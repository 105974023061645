import React from 'react';
import { Col, Row } from 'reactstrap';
import MainMediaModel from '../../../../../../../models/v2/main-media/main-media.model';
import { extractMainImageObjectFromArray } from '../../../../../../Partials/Sidebar/media-refactored/helpers/general';
import LiveBlogImageContainer from './live-blog-main-image.container';

type Properties = {
	projectDomain: string;
	mainMedia: MainMediaModel[];
	t: any;
	type: string;
};

export const LiveBlogMainImage: React.FunctionComponent<Properties> = ({ t, projectDomain, mainMedia, type }) => {
	const media: MainMediaModel = extractMainImageObjectFromArray(mainMedia) || new MainMediaModel();

	if (mainMedia.length && type !== mainMedia[0].resourceType) {
		return null;
	}

	return (
		<>
			{media.resourceId && media.resourceId.length > 0 && (
				<Row>
					<Col col='12' sm='12' md='12' className='mb-2'>
						<LiveBlogImageContainer imageId={media && media.resourceId ? media.resourceId : ''} projectId={projectDomain} />
					</Col>
				</Row>
			)}
		</>
	);
};
