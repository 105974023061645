export const liveBlogMainMediaTypesOptions: string[] = ['image', 'gallery', 'video', 'embed', 'background', 'share'];

export enum MainMediaTypes {
	image = 'image',
	video = 'video',
	gallery = 'gallery',
	embed = 'embed',
	background = 'background',
	share = 'share',
}

export const selectionsToMainMediaTypes = (selections: any[]) => {
	if (selections && selections.length > 0) {
		return selections.map((selection: any) => selection.value);
	}

	return [];
};

export const mainMediaTypesToOptions = (elements: any[], t: any) => {
	if (elements && elements.length > 0) {
		return elements.map((element: string) => mainMediaTypesToOption(element, t));
	}

	return [];
};

export const mainMediaTypesToOption = (element: any, t: any) => {
	if (element) {
		return {
			value: element,
			label: t(`${element}_title`),
		};
	}

	return {};
};
